<!--
File: RoadEditForm.vue
Description: form for adding/editing a single road.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <!--<NetworkDropdown class='rn-select-dropdown' :label="$t('road_network.road_network')" v-model="fk_rn"
            :initial_value="1" data-vv-name="fk_rn" v-validate="modelValidations.fk_rn" :is_required="true" />-->

          <div class="md-layout-item md-small-size-100 md-size-20">
            <RoadClassesDropdown class='road-class-select-dropdown' :label="$t('road_network.road_class')"
              v-model="road_class" data-vv-name="road_class" :initial_value="road_class" :is_required="true" required />
          </div>

          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field :class="[
              { 'md-valid': !errors.has('road_number') }, { 'md-error': errors.has('road_number') }
            ]">
              <label>{{ $t('road_network.road_number') }}</label>
              <md-input v-model="road_number" type="number" data-vv-name="road_number" required
                v-validate="modelValidations.road_number" />
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field :class="[{ 'md-valid': !errors.has('road_key') }, { 'md-error': errors.has('road_key') }]">
              <label>{{ $t('road_network.road_key') }}</label>
              <md-input v-model="road_key" type="text" data-vv-name="road_key" required
                v-validate="modelValidations.road_key" />
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field :class="[{ 'md-valid': !errors.has('road_length') }, { 'md-error': errors.has('road_length') }]">
              <label>{{ $t('road_network.length') }}</label>
              <md-input v-model="road_length" type="number" data-vv-name="road_length" required
                v-validate="modelValidations.road_length" />
            </md-field>
          </div>
        </div>
        <!--
  uncomment this if we user MultilanguageEditForm and delete the <div> section below
          <multilanguage-edit-form :theTable="'road'" :theId=this.road_id :oper="this.oper" v-model=theValues
          data-vv-name="theValues"></multilanguage-edit-form>
-->
        <div class="md-layout-item md-small-size-100 md-size-40">
          <md-field :class="[
              { 'md-valid': !errors.has('road_description_ru') }, { 'md-error': errors.has('road_description_ru') }
            ]">
            <label>{{ $t('stdCols.name_ru') }}</label>
            <md-input v-model="road_description_ru" type="text" data-vv-name="road_description_ru" required
              v-validate="modelValidations.road_description_ru" />
          </md-field>
          <md-field :class="[
              { 'md-valid': !errors.has('road_description_en') }, { 'md-error': errors.has('road_description_en') }
            ]">
            <label>{{ $t('stdCols.name_en') }}</label>
            <md-input v-model="road_description_en" type="text" data-vv-name="road_description_en" required
              v-validate="modelValidations.road_description_en" />
          </md-field>
        </div>


        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import Swal from 'sweetalert2'
//import NetworkDropdown from '../Dropdowns/NetworkDropdown.vue'
import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
//import MultilanguageEditForm from './MultilanguageEditForm.vue'

export default {
  name: 'road-edit-form',
  data() {
    return {
      //      theValues: { description_en: null, description_ru: null, description_kg: null },
      road_id: null,
      fk_rn: 1,
      road_class: '',
      road_number: null,
      road_key: null,
      road_description_en: null,
      road_description_ru: null,
      //      road_description_kg: null,
      road_length: null,
      saved_successfully: false,
      modelValidations: {
        fk_rn: { required: true },
        road_class: { required: true },
        road_number: { required: true, numeric: true, min_value: 1 },
        road_key: { required: true, min: 3 },
        road_description_en: { required: true, min: 3 },
        road_description_ru: { required: true, min: 3 },
        // road_description_kg: { required: true, min: 3 }
        road_length: { required: true, numeric: true, min_value: 1 },
      }
    }
  },
  props: {
    oper: String
  },
  components: {
    //NetworkDropdown,
    RoadClassesDropdown
    //    MultilanguageEditForm
  },
  created() {
    const { rid = null } = this.$route.params
    this.road_id = rid
    if (this.oper === 'upd' && rid) {
      this.$store.dispatch('LOAD_ROAD_BY_ID', rid).then((res) => {
        const theRoad = Array.isArray(res) ? res[0] : res
        this.fk_rn = theRoad.fk_rn
        this.road_key = theRoad.road_key
        this.road_class = theRoad.road_class
        this.road_number = theRoad.road_number
        this.road_length = theRoad.length_m
        // Delete these if we use MultilanguageEditForm 
        this.road_description_en = theRoad.road_description_en
        this.road_description_ru = theRoad.road_description
      })
    }
  },

  methods: {
    onClose() {
      this.$store.dispatch('SET_USE_HISTORY')
      history.back()
      history.replaceState({}, "", "")
      //this.$store.commit('SET_HISTORY')

      // revise how and where to return if were adding new
      //localStorage.setItem('history_for', (this.oper === 'add') && (this.saved_successfully) === true ? '' : 'roads')
    },
    async validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const theRoad = {
            fk_rn: this.fk_rn,
            road_class: this.road_class,
            road_number: Number(this.road_number),
            length_m: Number(this.road_length),
            road_key: this.road_key,
            road_description_en: this.road_description_en,
            road_description: this.road_description_ru,
            //road_description_kg: this.road_description_kg
          }
          const alert = {
            icon: 'success',
            text: this.$t(`road_network.road`) + this.$t(`label.was_saved`, {
              name_ru: this.road_description_ru,
              name_en: this.road_description_en
            }),
            footer: ''
          }

          const reqData = this.oper === 'add' ? theRoad : { id: this.road_id, theRoad }
          this.$store.dispatch(`${this.oper.toUpperCase()}_ROAD`, reqData).then(
            () => {
              this.saved_successfully = true
              Swal.fire(alert).then(() => {
                this.$nextTick(() => this.$validator.reset())
              })
            },
            (err) => {
              alert.icon = 'error'
              alert.text = this.$t(`road_network.road`) + this.$t(`label.was_not_saved`, {
                name_ru: this.road_description_ru,
                name_en: this.road_description_en
              })
              alert.footer = err
              Swal.fire(alert)
            }
          )
        }
      })
      // To return back to the list
      this.onClose()
    }
  },
  watch: {
  },
  computed: {
    saveBtnDisabled() {
      return this.errors.items.length > 0
      /*
      return !this.fk_rn || this.fk_rn === '' || !this.road_class || this.road_class === '' ||
        !this.road_number || this.road_number === '' ||
        !this.road_key || this.road_key === '' || !this.road_description_en || this.road_description_en === '' ||
        !this.road_description_ru || this.road_description_ru === ''
      */
    }
  }
}
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
